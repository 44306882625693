<template>
    <cricketWidget ></cricketWidget>
</template>

<script>
    import cricketWidget from '../Cricket/Livecenter/MatchDetail/cricket-widget';
    export default {
        name: 'cricketpanel',
        data() {
            return {
            }
        },
        components: {cricketWidget},
        mounted() {
            console.log(this.$route)
        },
        serverPrefetch() {},

        methods: {}
    }
</script>
<style lang="scss">
@import '~@/assets/scss/cricket.scss';
</style>